export default function Comment({body,at,author}:{body:string,at:string,author:string}){
  return (
    <div className="comment">
      <p>{body}</p>
      <hr />
      <p className="author">
        by {author}<br />
        at {at}
      </p>
    </div>
  );
}
