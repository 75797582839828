/* eslint-disable react-hooks/exhaustive-deps */
import {User} from "@firebase/auth-types";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {Link, useHistory, useLocation} from "react-router-dom";
import dayjs from "dayjs";
import Loading from '../components/Loading';
import './EntryList.scss';
import {Checkbox, FormControlLabel, TextField} from "@material-ui/core";
import Rating from '@material-ui/lab/Rating';
import queryString from 'query-string';
import ExcelJS from "exceljs";

export default function EntryList({match, user}: { match: any | null, user: User | null }) {
  const page = parseInt(match.params.page, 10);
  const location = useLocation();
  const params: any = queryString.parse(location.search);
  const [loading, setLoading] = useState<boolean>(true);
  const [entries, setEntries] = useState<any[]>([]);
  const [curEntries, setCurEntries] = useState<any[]>([]);
  const [is2nd, setIs2nd] = useState<boolean>(params.is2nd);
  const pagingSize = 25;
  const history = useHistory();
  useEffect(() => {
    if (user) {
      fetchList()
    }
  }, [user]);
  useEffect(() => setPage(page), [page]);
  const fetchList = async () => {
    setLoading(true);
    try {
      const {data} = await axios.get("https://app.q-uad.me/api/entry/list.json?col=name,name_kana,university,jimusho,grade,next_grade,gender&hasMeta=1", {
        // const {data} = await axios.get("http://localhost:8765/api/entry/list.json?col=name,university", {
        headers: {
          "x-access-token": "9e66a88f1f430dd05a03205fe19b5c9bee64bdb1a31759446c9666eac8e57cee",
          "x-firebase-user-id": user?.uid
        }
      });
      let tmpEntries = [];
      tmpEntries = data.result.entries;
      if (params.name) {
        const regex = new RegExp(`${params.name}`, 'g');
        tmpEntries = tmpEntries.filter((entry: any) => regex.test(entry.name) || regex.test(entry.name_kana));
      }
      if (params.university) {
        const regex = new RegExp(`${params.university}`, 'g');
        tmpEntries = tmpEntries.filter((entry: any) => regex.test(entry.university))
      }
      if (params.free) {
        const regex = new RegExp(`${params.free}`, 'g');
        tmpEntries = tmpEntries.filter((entry: any) => regex.test(JSON.stringify(entry)))
      }
      if (params.mystar) {
        tmpEntries = tmpEntries.filter((entry: any) => {
          return (entry.meta_data?.rating.find((r: any) => r.email === user?.email)?.rate === parseInt(params.mystar, 10))
        });
      }
      if (params.is2nd) {
        tmpEntries = tmpEntries.filter((entry: any) => {
          return entry.status === 2
        });
      }
      console.log(tmpEntries);
      setEntries(tmpEntries);
      setCurEntries(tmpEntries.slice(pagingSize * (page - 1), pagingSize * page));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false)
    }
  }
  const setPage = (num: number) => {
    setCurEntries(entries.slice(pagingSize * (num - 1), pagingSize * num));
  }
  const createAudienceAll = async (target: any) => {
    try {
      await axios.post("https://app.q-uad.me/api/audience/create.json", {
          //     const {data} = await axios.post("http://localhost:8765/api/audience/create.json", {
          description: "AllEntryUsers",
          userIDs: entries.map((v) => v.line_user_id)
        },
        {
          headers: {
            "x-access-token": "9e66a88f1f430dd05a03205fe19b5c9bee64bdb1a31759446c9666eac8e57cee",
            "x-firebase-user-id": user?.uid
          }
        });
      alert("オーディエンスを作成しました");
      target.disabled = true;
    } catch (error) {
      console.error(error);
    }
  }
  const renderPagingContainer = (page: number, entries: any) => {
    return (
      <div className="paging-container">
        {
          page !== 1 &&
          <Link to={`/list/${page - 1}?${queryString.stringify(queryString.parse(location.search))}`}
                className="is-primary button is-small"> « </Link>
        }
        <p style={{"padding": "10"}}>
          {`${entries.length}件中 ${pagingSize * (page - 1) + 1}-${pagingSize * (page - 1) + curEntries.length}件を表示中`}
        </p>
        {
          pagingSize * (page - 1) + curEntries.length < entries.length &&
          <Link to={`/list/${page + 1}?${queryString.stringify(queryString.parse(location.search))}`}
                className="is-primary button is-small"> » </Link>
        }
      </div>
    )
  }
  return (
    <>
      <section className="section">
        <div className="container">
          <h1 className="title">エントリーシート一覧</h1>
          {loading ? <Loading width={48}/> :
            /**
             * check size of entries
             * valid or redirect.
             */
            <>
              {
                (user?.email === "is@age.co.jp") ?
                  <div className="container">
                    <button className="button is-primary" onClick={(e) => {
                      createAudienceAll(e.target);
                    }}>すべてのユーザでオーディエンスを作成
                    </button>
                  </div>
                  : <></>
              }
              <button className="button is-success" style={{"marginLeft": "10px"}} onClick={async (e) => {
                console.log(`crate Excel`);
                const workbook = new ExcelJS.Workbook();
                workbook.addWorksheet("Sheet1");
                const worksheet = workbook.getWorksheet('Sheet1');
                if(worksheet){
                  worksheet.columns = [
                    {header: "連番", key: 'grouped_id'},
                    {header: "line_user_id", key: 'line_user_id'},
                    {header: "管理ID", key: 'id'},
                    {header: "名前", key: 'name'},
                    {header: "大学", key: 'university'},
                    {header: "性別", key: 'gender'},
                    {header: "現学年", key: 'grade'},
                    {header: "新学年", key: 'next_grade'},
                    {header: "事務所", key: 'jimusho'}
                  ];
                  worksheet.addRows(entries);
                }
                const uint8Array = await workbook.xlsx.writeBuffer();
                const blob = new Blob([uint8Array], {type: 'application/octet-binary'});
                const url = window.URL.createObjectURL(blob);
                const downloader = document.createElement('a');
                downloader.href = url;
                downloader.download = `announce-award-4th.xlsx`;
                downloader.click();
                downloader.remove();
              }}>現在のリストを出力
              </button>
              <div style={{"padding": "20px 0"}}>
                <form method="get" style={{"display": "flex", "flexDirection": "row", "alignItems": "center"}}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="is2nd"
                        color="primary"
                        value="1"
                        checked={is2nd}
                        onChange={() => {
                          setIs2nd(!is2nd);
                        }}
                      />
                    }
                    label="書類通過"
                  />
                  <TextField id="name" name="name" label="名前" type="search" variant="outlined"
                             defaultValue={params.name} style={{"marginRight": "1em"}}/>
                  <TextField id="university" name="university" label="大学名" type="search" variant="outlined"
                             defaultValue={params.university} style={{"marginRight": "1em"}}/>
                  <Rating name="mystar" style={{"marginRight": "0em"}} defaultValue={parseInt(params.mystar, 10)}
                          size="large"/>
                  <button className="button is-warning" type="submit">検索</button>
                </form>
              </div>
              {renderPagingContainer(page, entries)}
              <div className="table-container">
                <table id="entryList" className="table is-fullwidth is-striped">
                  <thead>
                  <tr>
                    <th>連番</th>
                    <th>ID</th>
                    <th>名前</th>
                    <th>大学</th>
                    <th>性別</th>
                    <th>現学年/新学年</th>
                    <th>エントリー日時</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    curEntries.map((entry, index) => {
                      return (
                        <tr key={entry.id}>
                          <td>
                            {entry.grouped_id}
                          </td>
                          <td>
                            <button className="is-text button" onClick={() => {
                              history.push(`/detail/${entry.id}`, {entries})
                            }}>
                              {entry.id}
                            </button>
                          </td>
                          <td>{entry.name}</td>
                          <td>{entry.university}</td>
                          <td>{entry.gender}</td>
                          <td>{entry.grade}/{entry.next_grade}</td>
                          <td>{dayjs(entry.created).format(`YYYY年MM月DD日 HH:mm`)}</td>
                        </tr>
                      )
                    })
                  }
                  </tbody>
                </table>
              </div>
            </>
          }
          {renderPagingContainer(page, entries)}
        </div>
      </section>
    </>
  );
}
