import {Redirect, Route, Switch} from "react-router-dom";
import EntryList from "./EntryList";
import EntryDetail from "./EntryDetail";
import React from "react";
import {User} from "@firebase/auth-types";
import './UserPage.css'


export default function UserPage({user}: { user: User | null }) {

  if(!user) {
    return <Redirect to="/login" />
  }

  return (
    <>
      <Switch>
        <Route path="/list/:page" render={props => <EntryList user={user} {...props} />}/>
        <Route path="/detail/:id" render={props => <EntryDetail user={user} {...props} />} />
        <Redirect to="/list/1"/>
      </Switch>
    </>
  )
}
